/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    pre: "pre",
    h3: "h3",
    blockquote: "blockquote",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "How to add anchors to headings in MDX? It's surprisingly easy to do. I assume you are already familiar with MDX so somewhere in your code you should have a blog page layout component that uses ", React.createElement(_components.code, null, "<MDXProvider>"), ", like that:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<MDXProvider>{children}</MDXProvider>\n")), "\n", React.createElement(_components.p, null, "MDX consists of components itself. So if there is a code like ", React.createElement(_components.code, null, "this"), " then the interpreter changes the inner value (children) into the same content but wrapped with ", React.createElement(_components.code, null, "<code></code>"), "."), "\n", React.createElement(_components.p, null, "The same applies to all the headings, lists, paragraphs, etc. Our job is to override the headings. We can do it by passing ", React.createElement(_components.code, null, "components"), " hash and specifying a replacement."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import H2 from './MyCustomMDX/H2';\nimport H3 from './MyCustomMDX/H3';\nimport H4 from './MyCustomMDX/H4';\n// ...\n<MDXProvider\n    components={{\n        h2: H2,\n        h3: H3,\n        h4: H4,\n    }}\n>\n    {children}\n</MDXProvider>;\n")), "\n", React.createElement(_components.p, null, "Please notice that we are not going to add an anchor to the ", React.createElement(_components.code, null, "<h1>"), " tag. It doesn't make sense in my opinion. ", React.createElement(_components.code, null, "<h1>"), " is like a summary of the whole page. The URL that links to it is the direct link to the post. Anchors should be used to specific parts of a post (to a section)."), "\n", React.createElement(_components.h3, null, "Override heading component"), "\n", React.createElement(_components.p, null, "The override for ", React.createElement(_components.code, null, "<h2>"), " that shows an anchor when the mouse is over the text could look like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "// ./MyCustomMDX/H2.js\nfunction getAnchor(text) {\n    return text\n        .toLowerCase()\n        .replace(/[^a-z0-9 ]/g, '')\n        .replace(/[ ]/g, '-');\n}\nconst H2 = ({ children }) => {\n    const anchor = getAnchor(children);\n    const link = `#${anchor}`;\n    return (\n        <h2 id={anchor}>\n            <a href={link} className=\"anchor-link\">\n                §\n            </a>\n            {children}\n        </h2>\n    );\n};\nexport default H2;\n")), "\n", React.createElement(_components.p, null, "Below you'll see the demo. Please hover over the text. On the left you should see ", React.createElement(_components.code, null, "§"), " sign that is also a link, representing our anchor:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement("a", {
    href: "/"
  }, " I'm h2 with an anchor"), "\n"), "\n", React.createElement(_components.p, null, "Let's explain a few bits. The way we use headings in Markdown is by using ", React.createElement(_components.code, null, "#"), " sign, for example:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-md"
  }, "## I'm h2 with an anchor\n")), "\n", React.createElement(_components.p, null, "Everything that goes after ", React.createElement(_components.code, null, "##"), " is passed as a child to the ", React.createElement(_components.code, null, "H2"), " component."), "\n", React.createElement(_components.p, null, "So the next interesting bit is done in the ", React.createElement(_components.code, null, "getAnchor"), " function. Take a look at lines ", React.createElement(_components.code, null, "3"), " to ", React.createElement(_components.code, null, "8"), ". This is what happens:"), "\n", React.createElement(_components.p, null, "line ", React.createElement(_components.code, null, "5"), " - we convert the input to lower case → \"i'm h2 with an anchor\"\nline ", React.createElement(_components.code, null, "6"), " - we remove all non-alphanumeric characters → \"im h2 with an anchor\"\nline ", React.createElement(_components.code, null, "7"), " - we replace spaces with a hyphen → \"im-h2-with-an-anchor\"\n... and voilà. We have a URL-friendly anchor 🎉"), "\n", React.createElement(_components.h3, null, "Styling"), "\n", React.createElement(_components.p, null, "Another important thing here is the CSS. We want to show the anchor only on hover and somewhere next to the heading itself:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "h2 {\n    position: relative;\n}\n.anchor-link {\n    color: #666;\n    opacity: 0;\n    position: absolute;\n    transform: translate(-1em, -2px);\n    width: 1em;\n}\nh2:hover .anchor-link {\n    opacity: 1;\n}\n")), "\n", React.createElement(_components.p, null, "Of course, you can go crazy with your anchors ;) That one is very basic."), "\n", React.createElement(_components.h3, null, "Recommendation"), "\n", React.createElement(_components.p, null, "One thing that is easy to overlook here (in my example) is using a character like ", React.createElement(_components.code, null, "§"), " inside of ", React.createElement(_components.code, null, "<h2>"), " tag. In this approach, ", React.createElement(_components.strong, null, "the sign will become a part of the document outline"), ". Which is not something we want. It's better to use an icon in SVG format for example but I didn't want to complicate the example."), "\n", React.createElement(_components.p, null, "If the simple sign is what you want then you should render ", React.createElement(_components.code, null, "<a>"), " tag before or after the ", React.createElement(_components.code, null, "<h2>"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
